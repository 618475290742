.Alert {
    display: flex;
    padding: 6px 16px;
    font-size: 0.875rem;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    line-height: 1.43;
    border-radius: 4px;
    letter-spacing: 0.01071em;
    border: 1px solid #f5c6cb;

    &-message {
        display: flex;
        padding: 8px 0;
        flex-direction: column;
        justify-content: center;
    }
    &-warning {
        color: rgb(97, 26, 21);
        background-color: rgb(253, 236, 234);
        &-icon {
            color: #f44336;
        }
    }
}
