.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.pd-10 {
    padding: 10px;
}
.pd-30 {
    padding: 30px;
}
