@import 'atoms/indexAtoms.css';

.handsontable table thead th {
    background-color: white;
    color: black;

    font-size: 12px;
}
.handsontable table tbody th {
    background-color: white;
    color: black;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    font-size: 12px;
}
