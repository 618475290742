@import 'indexCSS.css';
body {
    min-height: 100vh;
}
.LandingPage {
    min-height: 100vh;
    height: 100%;
}
.ImageAvatars--landingpage {
    width: 40%;
    height: 100%;
    display: flex;
}
.centerItems {
    display: flex;
    justify-content: center;
}
.LandingPage--buttons:hover {
    cursor: pointer;
}
.LandingPage--buttons:hover {
    transform: scale3d(1.1, 1.1, 1.1);
    transition: 1s;
}

#myProgress {
    width: 100%;
    background-color: #ddd;
}

#myBar {
    width: 1%;
    height: 30px;
    background-color: #4caf50;
}
a.MuiIconButton-colorInherit:hover,
a.MuiButtonBase-root:hover {
    color: inherit;
}
a.MuiButton-containedPrimary:hover {
    color: white;
}
.container {
    margin-bottom: 5px !important;
    padding: 10px !important;
}
.row .column h1 {
    text-align: center;
}
.content {
    display: flex !important;
    justify-content: center !important;
}

#root {
    background: #f2f2f2;
}
.MinHeight {
    min-width: 100vh;
    min-height: calc(100vh - 65px);
}
.table {
    height: inherit !important;
}

.center-table-definition {
    max-width: 85%;
}

.left-bar-definition {
    max-width: 15%;
}
.demand-title {
    padding: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    font-size: 1.3rem;
    text-transform: uppercase;
}
.demand-card-title {
    color: #666;
    font-size: 1.1rem;
    text-transform: uppercase;
    font-weight: 500;
}
.requests-bar {
    height: calc(100% - 205px);
    display: block !important;
    background-color: #f2f2f2;
    overflow-y: auto;
    overflow-x: hidden;
    border-bottom: 1px solid #d3d3d36b;
    border-top: 1px solid #d3d3d36b;
    border-right: 5px solid #FFF;
    border-left: 5px solid #FFF;
}
.time-round-info{
    padding: 15px;
    height: 155px;
    align-items: center;
}
.GameCard .GameCardImage {
    height: 200px;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #e2e5f9;
}

.GameCard .GameCardFooter {
    justify-content: center;
}
.GameCard .GameCardRow {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}
.GameCard .GameCardRow .GameCardDateIcon {
    margin-right: 5px;
}
.GameCard .GameCardStatus {
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-right: 5px;
    text-align: center;
    border-radius: 50%;
}
.GameCard .GameCardStatusWrap{
    color: #666;
    font-size: .8rem;
    line-height: 15px;
    text-transform: uppercase;
    font-weight: 500;
}
.GameCard .GameCardStatusWrap .GameCardStatusText{
    line-height: 1;
    margin-top: 3px;
    display: inline-block;
}
.game_status_STOPPED, .game_status_ENDED{
    background-color: #b71c1c;
}
.game_status_RUNNING {
    background-color: #558b2f;
}
.game_status_ADMIN_PAUSED,
.game_status_END_MONTH_PAUSED{
    background-color: #f57f17;
}
.ReportsTableTitle.MuiTypography-root{
    margin: 0 0 5px 16px;
    font-weight: 600;
}
.ReportsTable{
    margin-bottom: 2rem;
}
.ReportsTable .SummaryRow .MuiTableCell-root{
    font-weight: 700;
    font-size: 1rem;
}
.ReportsTable .SummaryRow.Expenses .MuiTableCell-root{
    color:#B71C1C;
}
.ReportsTable .SummaryRow.Gains .MuiTableCell-root{
    color: #2E7D32;
}
.ReportsTable .MuiTableRow-root .MuiTableCell-root:first-child{
    font-weight: 700;
}
.ReportsTable .MuiTableRow-root .MuiTableCell-root:last-child{
    text-align: right;
}
.ReportsTable .MuiTableRow-root:nth-of-type(even) .MuiTableCell-root{
 background-color: #f2f2f2;
} 

.EventsLegend{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px;
}
.EventsLegend .EventsLegendIcon{
    height: 13px;
    width: 20px;
    margin-right: 5px;
}

.eventCalendar .calendar{
    overflow: hidden;
}
.ServicesItems{
    column-count: 2;
}
.shake{
    animation: shake 2.5s cubic-bezier(.36,.07,.19,.97) both;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
}
@keyframes shake {
    5%, 45% {
      transform: translate3d(-1px, 0, 0);
    }
    
    10%, 40% {
      transform: translate3d(2px, 0, 0);
    }
  
    15%, 25%, 35% {
      transform: translate3d(-4px, 0, 0);
    }
  
    20%, 30% {
      transform: translate3d(4px, 0, 0);
    }
  }
@media only screen and (max-width: 1750px) {
    .center-table-definition {
        max-width: 80%;
    }

    .left-bar-definition {
        max-width: 20%;
    }
}

@media only screen and (max-width: 1300px) {
    .center-table-definition {
        max-width: 75%;
    }

    .left-bar-definition {
        max-width: 25%;
    }
}
