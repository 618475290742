.Tab {
    min-height: calc(100% / 5 - 1.7rem);
    width: 55px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
        0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    display: flex;
    font-size: small;
    cursor: pointer;
    margin-top: 25px;
    writing-mode: vertical-lr;

    &--active {
        border-right: 3px solid #3f51b5;
    }
    .Label {
        //  transform: rotate(270deg);
        clear: both;
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
    }
}

@media screen and(max-height: 1000px) {
    .Tab {
        min-height: calc(100% / 5 - 1.45rem);
        .Label {
            font-size: 1rem;
        }
    }
}
