.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-input-error {
    border: 1px solid red;
}
.form-control:disabled,
.form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1;
}
.form-padd {
    padding-right: 5px;
    padding-left: 5px;
}
.form-label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: calc(0.375rem + 1px);
    padding-bottom: calc(0.375rem + 1px);
    margin-bottom: 0;
    font-size: 16px;
    /* line-height: 32px; */
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
.form-group {
    margin-bottom: 0.5rem !important;
    &-pdAround {
        padding: 0px 10px 0px 10px;
    }
}

.form-example {
    position: relative;
    padding: 1em 1em 1em;
    border: 1px solid #d8d8d8;
    // margin: 1.5rem 0 0;
    width: 100%;
}
.form-heading {
    margin-bottom: 0.8rem !important;
}
.form-control-error {
    color: #f44336;
}
.form-control-contained-error {
    text-align: left;
    margin-left: 14px;
    margin-right: 14px;
}
