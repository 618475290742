.MainContent {
    display: flex;
    min-height: calc(100vh - 64px);
    width: 100%;
}
.MainContentRight {
    width: calc(100% - 240px);
    padding: 2rem;
}
.height100 {
    height: 100%;
}
.FlexCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}
