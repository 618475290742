.txt-al {
    &--cen {
        text-align: center;
    }
    &--lef {
        text-align: left;
    }
    &--rig {
        text-align: right;
    }
}
