#MarketServices{
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    overflow-y: hidden;
    user-select: none;

    .MarketServicesHotel{
        flex: 1;
        min-width: 25%;
        border-right: 1px solid rgb(204, 204, 204);
        padding-bottom: 1rem;
        font-size: 1rem;
        &:last-child{
            border-right: none;
        }
        .MarketServicesHotelName{
            color: #FFFFFF;
            background-color: #3F51B5;
            padding: .5rem 2rem;
            margin-bottom: 1rem;
            font-weight: 500;
            font-size: 1.1rem;
        }
        .MarketService{
            margin: 5px 0;
            padding: 0 2rem;
        }
        .MarketServiceName{
            font-weight: 500;
            color: #555;
            font-size: 1rem;
        }
        .MarketServicePrice{
            text-align: right;
        }
    }
}