.Login {
    background-size: cover;
    background-repeat: no-repeat;
    .form {
        padding: 30px;
    }
    .loginInner {
        min-height: 100vh;
    }
    .loginAppName{
        font-weight: 800;
        margin-bottom: 1rem;
    }
}
