.Timer {
    /*position: absolute;
    right: 50px;*/
    font-family: 'Montserrat';
    display: flex;
    flex-direction: column;
    align-items: center;
    // bottom: 20px;
    margin-left: 15px;
    background: white;

    &-modal {
        &--center {
            display: flex;
            align-items: center;
            flex-direction: column;
        }
    }
    .text {
        color: #aaa;
    }
    h1 {
        font-family: 'Roboto';
        text-align: center;
        margin-bottom: 40px;
    }

    .value {
        font-size: 40px;
    }

    .info {
        max-width: 360px;
        margin: 40px auto 0;
        text-align: center;
        font-size: 16px;
    }
}
#Timer {
    &--icon {
        color: #4caf50;
        font-size: 3em;
        animation: shake 0.9s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
        transform: translate3d(0, 0, 0);
        animation-iteration-count: 2;
    }
}

@keyframes shake {
    10%,
    90% {
        transform: translate3d(-1px, 0, 0) rotate(-10deg);
    }

    20%,
    80% {
        transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(4px, 0, 0) rotate(10deg);
    }
}
