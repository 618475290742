@import 'layout/index';
@import 'components/components';
@import 'atoms/index';
@import 'pages/index';

$blueBackground: #2185d021;
$greenBackground: rgb(191, 255, 161);
$orangeBackground: rgb(255, 211, 117);



.Table {
    //width: 1000px;
    width: calc(100% + 10px);
    font-size: 0.8rem;

    th {
        visibility: visible !important;
        font-size: 0.8rem !important;
    }

    &--blue {
        td {
            background: $blueBackground;
        }
    }

    &--green {
        td {
            background: $greenBackground;
        }
    }

    &--orange {
        td {
            background: $orangeBackground;
        }
    }
}
.stickyHeader,
.stickyFooter {
    display: flex;
    position: sticky;
    z-index: 5;
    height: 50px;
    padding: 0 20px;
    background-color: #ffffff;
}
.stickyHeader {
    top: 0;
    left: 0;
    border-bottom: 1px solid #d3d3d36b;
}
.stickyFooter {
    bottom: 0;
    left: 0;
    border-top: 1px solid #d3d3d36b;
}
.textCenter {
    text-align: center;
}
.HotTableWrap {
    margin: 20px auto;
    overflow: hidden;
    td,
    th {
        vertical-align: middle;
    }
    .rowHeader {
        color: rgba(0, 0, 0, 0.87);
        font-weight: 500;
    }
    .colHeader {
        color: #ffffff;
        font-weight: 500;
    }
    .ht_clone_left,
    .ht_clone_top,
    .ht_clone_top_left_corner {
        z-index: 1;
    }
    .handsontable tr td:last-child,
    .handsontable tr th:last-child {
        border-right: none;
    }
    .handsontable tr:first-child td,
    .handsontable thead tr:first-child th {
        border-top: none;
    }
    .handsontable tr:last-child td,
    .handsontable tr:last-child th {
        border-bottom: none;
    }
    .handsontable tr th:first-child {
        border-left: none;
    }
    .handsontable table thead th {
        background-color: #3f51b5;
        &.ht__highlight {
            background-color: #212c69;
        }
    }
    .handsontable tbody th {
        text-align: left;
        padding: 0 10px;
    }
    .handsontable .htNoFrame + td,
    .handsontable .htNoFrame + th,
    .handsontable.htRowHeaders thead tr th:nth-child(2),
    .handsontable td:first-of-type,
    .handsontable th:first-child,
    .handsontable th:nth-child(2) {
        border-left: 3px solid #3f51b5;
    }
    .handsontable input,
    .handsontable textarea {
        text-align: center;
        line-height: 30px;
    }
}
