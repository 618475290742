$colorPrimary: #009aff;

.ArrowDown {
    color: $colorPrimary;
    font-size: 2rem;
    box-sizing: border-box;
    height: 2vw;
    width: 2vw;
    cursor: pointer;
    border-style: solid;
    border-color: $colorPrimary;
    border-width: 0px 1px 1px 0px;
    transform: rotate(45deg);
    transition: border-width 150ms ease-in-out;
    &:hover {
        border-bottom-width: 3px;
        border-right-width: 3px;
    }
}
.ArrowUp {
    color: $colorPrimary;
    font-size: 2rem;
    box-sizing: border-box;
    height: 2vw;
    width: 2vw;
    cursor: pointer;
    border-style: solid;
    border-color: $colorPrimary;
    border-width: 1px 0px 0px 1px;
    transform: rotate(45deg);
    transition: border-width 150ms ease-in-out;
    &:hover {
        border-top-width: 3px;
        border-left-width: 3px;
    }
}
