.CurrentGameDate{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.1rem;
    font-weight: 700;
    .MuiSvgIcon-root{
        margin-right: 5px;
    }
}

.RoundTime {
    position: relative;
    width: 75px;
    height: 75px;
    margin: 0 auto;
    .text {
        position: absolute;
        top: 16px;
        width: 100%;
        text-align: center;
    }
    .value {
        bottom: 10px;
        position: absolute;
        text-align: center;
        width: 100%;
        color: #333;
        font-weight: 500;
        font-size: 20px;
    }
}
