.adminPageTitle {
    font-weight: bold;
    font-size: 1.7rem;
    margin-bottom: 0.5rem;
    margin-left: 1rem;
}
.adminPageSubtitle {
    margin-bottom: 1rem !important;
}
.adminMainContent {
    padding: 2rem;
}
.adminPaperBox {
    overflow: hidden;
}
.editGameForm {
    max-width: 800px;
    margin-bottom: 3rem;
}
.readonly,
input:read-only {
    color: #777 !important;
}
.tableTitle {
    font-weight: 800 !important;
}
