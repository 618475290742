.wtHolder {
    overflow: hidden !important;
}
.textarea.HandsontableCopyPaste {
    display: none;
}
.txt-changed {
    color: red;
}
.HandsontableCopyPaste {
    display: none;
}
.yellow-bg {
    background-color: #fdf7e3;
}
.white-bg {
    background-color: white;
}
